

@page {
  size: A3;
  margin: 2cm;
}

@page {
  @top-center {
    margin-top: 2cm;
  }
}
