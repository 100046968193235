@import "./grid/grid.less";

.container() {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}
.container-max() {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.centered() {
  padding-left: 20px;
  padding-right: 20px;

  @media @md {
    .container;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.centered-max() {
  padding-left: 20px;
  padding-right: 20px;

  @media @lg {
    .container-max;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.make-full-content() {
  display: block;
  .container;
}

.make-two-column-layout(@reverse: false) {
  ::ng-deep &__before-content {
    @media @sm-down {
      .ubl-container();
      flex-direction: column;
    }
  }

  ::ng-deep &__content {
    .make-breadcrumbs();
    width: 100%;
    padding-bottom: 30px;

    @media @sm {
      padding-bottom: 60px;
    }
  }

  ::ng-deep &__aside {
    @media print{
      display: none !important;
    }

    .make-section-title();

    > .section-title{
      margin-bottom: 15px;
    }

    .stacked-navigation {
      margin-bottom: 40px;

      & .stacked-navigation {
        margin-bottom: 0;
      }
    }

    @media @sm-down {
      .stacked-navigation {
        display: none;
      }
    }  
  }
  
  ::ng-deep .grid-row {
    @media print {
      grid-template-columns: 100% !important;
    }
  }

  ::ng-deep &__title {
    .make-page-title;
  }
}

.make-page-title() {
  font-weight: bold;
  margin: 0;
  margin-bottom: 18px;
  font-size: 30px;
  //font-size: 26px;
  line-height: 1.33;
  //line-height: 34px;

  @media @sm {
    font-size: 50px;
    line-height: 1.24;
    margin-bottom: 30px;
    //margin-bottom: 44px;
  }
}

.make-page-host() {
  display: block;
  margin-top: 10px;
  @media @sm {
    margin-top: 65px;
    .ubl-container();
  }
}

.make-breadcrumbs() {
  .breadcrumbs {
    padding-bottom: 18px;
    border-bottom: 1px solid #a0a0a0;
    margin-bottom:30px;

    @media @sm {
      border-bottom-width: 2px;
    }
  }
}

.breadcrumbs{
  @media print{
    display: none !important;
  }
}

.make-section-title() {
  .section-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    margin: 0;
    //margin-bottom: 20px;
    margin-bottom: 34px;

    @media @sm-down {
      display: none;
    }
  }
}
