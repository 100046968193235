@import './foundation/index.less';
@import './base/_type.less';
@import './base/_layout.less';
@import './base/grid/grid.less';
@import './panels/index.less';
@import './file-icon-extensions.less';
@import './icons/style.less';

h1 {
  .h1;
}

h2 {
  .h2;
}

a,
a:visited {
  color: @link-color;

  &:hover {
    text-decoration: underline;
  }

  // @media print {
  //   color: #000 !important;
  // }
}

p {
  margin: 0;
}

.link {
  &--external {
    position: relative;
    padding-left: 1.25em;

    &::before {
      content: @icon-link;
      position: absolute;
      font: 0.9em @icomoon-font-family;
      line-height: inherit;
      top: 0;
      left: 0;
    }
  }
}

.page__aside {
  .subscribe__form .input-n-btn-block {
    @media @sm {
      flex-direction: column;

      button.submit.register-btn {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}