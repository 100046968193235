:root {
  --white: #ffffff;
  --black: #000000;
  --steel-grey: #f2f0f0;
  --concrete-grey: #dad8d8;
  --greyblue: #67a9af;
  --marine: #083959;
  --warm-grey: #999999;
  --warm-grey-two: #777777;
}
@import './styles/index.less';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: @font-family;
  margin: 0;
  min-height: 100%;
  &.no-scroll {
    overflow: hidden;
  }
}

.gtx-trans-icon {
  display: none;
}

.rte {
  .rte();
}

a {
  &:active {
    color: @red;
    text-decoration:none;
  }
}

.popup {
  .text-panel {
    margin-top: 0;
  }
}

ubl-link,
input,
button,
div,
textarea,
a {
  &:focus {
    outline: 1px solid currentColor;
  }
}

.skip-navigation {
  .button();
  position: absolute;
  left: 0;
  top: 0;
  text-decoration: none;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;

  &:active,
  &:visited {
    color: #fff;
  }

  &:hover {
    color: @link-color;
  }

  &:focus {
    overflow: visible;
    clip: initial;
  }
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

[hidden]{
  display:none !important;
}