.rte() {
  color: @text-color;
  font-family: @font-family;

  p {
    margin: 0 0 30px;
    line-height: 1.5;
    font-size: 18px;

    @media @sm {
      font-size: 22px;
    }

    &:last-child {
      //margin: 0;
    }
  }

  a {
    color: @link-color;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: @red;
    }

    .blue-theme &,
    .green-theme &,
    .black-theme & {
      color: inherit;
    }
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    padding-left: 20px;
    //margin-bottom: 30px;
    margin: 0;
    line-height: 1.5;
    //line-height: 1.3;
    font-size: 18px;

    @media @sm {
      font-size: 22px;
    }
  }

  ul {
    list-style-type: disc;
    margin-bottom: 30px;
  }

  ol {
    // margin-bottom: 10px;
    margin-bottom: 30px;
    list-style-type: decimal;
  }

  em {
    font-style: italic;
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  .avoid-page-break-inside {
    page-break-inside: avoid;

    p:last-child {
      margin-bottom: 30px;
    }
  }

  /**umb_name:h2*/
  h2 {
    font-size: 25px;
    //font-size: 18px;
    //line-height: 24px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 4px;
  }

  /**umb_name:h3*/
  h3 {
    font-size: 22px;
    //font-size: 17px;
    line-height: 22px;
    padding-bottom: 2px;
    margin: 0;
  }

  /**umb_name:small*/
  text--small {
    font-size: 22px;
    color: #000;
  }
}
