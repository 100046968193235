.h1() {
  font-family: @font-family-2;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
}

.h2() {
  font-family: @font-family-2;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.5;
}

.h2-big() {
  .h2;
  font-size: 26px;
  font-weight: bold;

  @media @sm {
    font-size: 40px;
  }
}

.h2-panel-title() {
  font-family: @font-family;
  color: @text-color;

  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
}

.h3-panel-title() {
  font-family: @font-family;
  color: @text-color;

  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;

  @media @sm {
    font-size: 32px;
  }
}

.h3() {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;

  &--normal {
    font-weight: normal;
  }

  @media @sm {
    font-size: 22px;
  }
}

.h3-big() {
  .h3;
  font-size: 24px;

  @media @sm {
    font-size: 32px;
  }
}

.h4() {
  font-family: @font-family-2;
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
}

.h4-big() {
  .h4;
  font-size: 22px;

  @media @sm {
    font-size: 25px;
  }
}

.epic() {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  border-top: 4px solid #0c84a5;
  padding-top: 14px;
  display: inline-block;
  vertical-align: top;

  &--no-line {
    border-top: 0;
    padding-top: 0;
  }

  ::ng-deep {
    .link__link {
      color: #000;
    }
  }
}

.link() {
  text-decoration: none;
  color: @link-color;
  border-bottom: 1px solid @link-underline-color;

  &:hover {
    border: 0;
  }
}

.large() {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  @media @sm {
    font-size: 22px;
  }
}

.button() {
  cursor: pointer;
  padding: 8px 10px;
  border: 2px solid @link-color;
  background: @link-color;
  color: @text-reverse-color;

  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.2px;

  &.active {
    background-color: #fff;
    color: @link-color;
  }

  &:focus {
    box-shadow: 0 0 5px @link-color;
  }

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;

    &:hover {
      background: @link-color;
      color: @text-reverse-color;
    }
  }

  @media (hover) {
    &:hover {
      background-color: #fff;
      color: @link-color;
    }
  }
}

.left-arrow() {
  &:before {
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    vertical-align: middle;

    border-top: 2px solid currentColor;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
    margin: 0 10px 2px 0;

    @media @md {
      margin: 0 10px 2px 2px;
    }
  }
}

.dl() {
  margin: 0;
  overflow: hidden;

  dt {
    float: left;
  }

  dd {
    overflow: hidden;
    margin: 0;
    padding-left: 5px;
  }
}
