:root {
  --white: #fff;
  --black: #333;
  --bg-color: var(--white);
    --on-bg-color: var(--black);
  --primary-color: #06546a;
    --on-primary-color: #fff;
  --secondary-color: rebeccapurple;
    --on-secondary-color: #fff;
  --warn-color: orange;
    --on-warn-color: #333;
  --error-color: red;
    --on-error-color: #fff;
  --text-color: var(--black);
  --text-color-light: gray;
  --button-primary-color: var(--primary-color);
    --on-button-primary-color: var(--on-primary-color);
  --button-secondary-color: var(--secondary-color);
    --on-button-secondary-color: var(--on-secondary-color);
  --button-warn-color: var(--warn-color);
    --on-button-warn-color: var(--on-warn-color);
  --button-error-color: var(--error-color);
    --on-button-error-color: var(--on-error-color);
  --ff-text: 'DINNextLTPro';
  --ff-headings: var(--ff-text);
  --font-size: 18px;
  --line-height: 24px;
  --link-color: @link-color;

  //grid
  --grid-column-gap: 2.63158%;
}
// Themes color
@light-background-color: #67a9af;
@dark-background-color: #016174;
@grey-background-color: #dad8d8;
@background-color: #fff;

@primary-color: #06546a;

// Colors
@link-color: #156570;
@link-underline-color: fade(@link-color, 35%);

@red: #b90000;

@key-color: #67a9af;
@key-underline-color: fade(@key-color, 70);

@text-color: #333;
@text-color--light: lighten(@text-color, 20);
@text-underline-color: fade(@text-color, 70);

@text-reverse-color: #fff;
@text-reverse-underline-color: fade(@text-reverse-color, 70);

// Fonts
@font-family: 'DINNextLTPro', Arial, Helvetica, sans-serif;
@font-family-2: 'DINNextLTPro', Arial, Helvetica, sans-serif;
